const kuhni = [
  {
    original: "/gallery/kuhni/p_0001.jpg",
    thumbnail: "/gallery/kuhni/t_0001.jpg",
  },
  {
    original: "/gallery/kuhni/p_0002.jpg",
    thumbnail: "/gallery/kuhni/t_0002.jpg",
  },
  {
    original: "/gallery/kuhni/p_0003.jpg",
    thumbnail: "/gallery/kuhni/t_0003.jpg",
  },
  {
    original: "/gallery/kuhni/p_0004.jpg",
    thumbnail: "/gallery/kuhni/t_0004.jpg",
  },
  {
    original: "/gallery/kuhni/p_0005.jpg",
    thumbnail: "/gallery/kuhni/t_0005.jpg",
  },
  {
    original: "/gallery/kuhni/p_0006.jpg",
    thumbnail: "/gallery/kuhni/t_0006.jpg",
  },
  {
    original: "/gallery/kuhni/p_0007.jpg",
    thumbnail: "/gallery/kuhni/t_0007.jpg",
  },
  {
    original: "/gallery/kuhni/p_0008.jpg",
    thumbnail: "/gallery/kuhni/t_0008.jpg",
  },
  {
    original: "/gallery/kuhni/p_0009.jpg",
    thumbnail: "/gallery/kuhni/t_0009.jpg",
  },
  {
    original: "/gallery/kuhni/p_0010.jpg",
    thumbnail: "/gallery/kuhni/t_0010.jpg",
  },
  {
    original: "/gallery/kuhni/p_0011.jpg",
    thumbnail: "/gallery/kuhni/t_0011.jpg",
  },
  {
    original: "/gallery/kuhni/p_0012.jpg",
    thumbnail: "/gallery/kuhni/t_0012.jpg",
  },
  {
    original: "/gallery/kuhni/p_0013.jpg",
    thumbnail: "/gallery/kuhni/t_0013.jpg",
  },
  {
    original: "/gallery/kuhni/p_0014.jpg",
    thumbnail: "/gallery/kuhni/t_0014.jpg",
  },
  {
    original: "/gallery/kuhni/p_0015.jpg",
    thumbnail: "/gallery/kuhni/t_0015.jpg",
  },
  {
    original: "/gallery/kuhni/p_0016.jpg",
    thumbnail: "/gallery/kuhni/t_0016.jpg",
  },
  {
    original: "/gallery/kuhni/p_0017.jpg",
    thumbnail: "/gallery/kuhni/t_0017.jpg",
  },
  {
    original: "/gallery/kuhni/p_0018.jpg",
    thumbnail: "/gallery/kuhni/t_0018.jpg",
  },
  {
    original: "/gallery/kuhni/p_0019.jpg",
    thumbnail: "/gallery/kuhni/t_0019.jpg",
  },
  {
    original: "/gallery/kuhni/p_0020.jpg",
    thumbnail: "/gallery/kuhni/t_0020.jpg",
  },
  {
    original: "/gallery/kuhni/p_0021.jpg",
    thumbnail: "/gallery/kuhni/t_0021.jpg",
  },
  {
    original: "/gallery/kuhni/p_0022.jpg",
    thumbnail: "/gallery/kuhni/t_0022.jpg",
  },
  {
    original: "/gallery/kuhni/p_0023.jpg",
    thumbnail: "/gallery/kuhni/t_0023.jpg",
  },
  {
    original: "/gallery/kuhni/p_0024.jpg",
    thumbnail: "/gallery/kuhni/t_0024.jpg",
  },
  {
    original: "/gallery/kuhni/p_0025.jpg",
    thumbnail: "/gallery/kuhni/t_0025.jpg",
  },
  {
    original: "/gallery/kuhni/p_0026.jpg",
    thumbnail: "/gallery/kuhni/t_0026.jpg",
  },
  {
    original: "/gallery/kuhni/p_0027.jpg",
    thumbnail: "/gallery/kuhni/t_0027.jpg",
  },
  {
    original: "/gallery/kuhni/p_0028.jpg",
    thumbnail: "/gallery/kuhni/t_0028.jpg",
  },
  {
    original: "/gallery/kuhni/p_0029.jpg",
    thumbnail: "/gallery/kuhni/t_0029.jpg",
  },
];

const legla = [
  {
    original: "/gallery/legla/p_0001.jpg",
    thumbnail: "/gallery/legla/t_0001.jpg",
  },
  {
    original: "/gallery/legla/p_0002.jpg",
    thumbnail: "/gallery/legla/t_0002.jpg",
  },
  {
    original: "/gallery/legla/p_0003.jpg",
    thumbnail: "/gallery/legla/t_0003.jpg",
  },
  {
    original: "/gallery/legla/p_0004.jpg",
    thumbnail: "/gallery/legla/t_0004.jpg",
  },
  {
    original: "/gallery/legla/p_0005.jpg",
    thumbnail: "/gallery/legla/t_0005.jpg",
  },
  {
    original: "/gallery/legla/p_0006.jpg",
    thumbnail: "/gallery/legla/t_0006.jpg",
  },
  {
    original: "/gallery/legla/p_0007.jpg",
    thumbnail: "/gallery/legla/t_0007.jpg",
  },
  {
    original: "/gallery/legla/p_0008.jpg",
    thumbnail: "/gallery/legla/t_0008.jpg",
  },
  {
    original: "/gallery/legla/p_0009.jpg",
    thumbnail: "/gallery/legla/t_0009.jpg",
  },
  {
    original: "/gallery/legla/p_0010.jpg",
    thumbnail: "/gallery/legla/t_0010.jpg",
  },
  {
    original: "/gallery/legla/p_0011.jpg",
    thumbnail: "/gallery/legla/t_0011.jpg",
  },
  {
    original: "/gallery/legla/p_0012.jpg",
    thumbnail: "/gallery/legla/t_0012.jpg",
  },
  {
    original: "/gallery/legla/p_0013.jpg",
    thumbnail: "/gallery/legla/t_0013.jpg",
  },
  {
    original: "/gallery/legla/p_0014.jpg",
    thumbnail: "/gallery/legla/t_0014.jpg",
  },
  {
    original: "/gallery/legla/p_0015.jpg",
    thumbnail: "/gallery/legla/t_0015.jpg",
  },
  {
    original: "/gallery/legla/p_0016.jpg",
    thumbnail: "/gallery/legla/t_0016.jpg",
  },
  {
    original: "/gallery/legla/p_0017.jpg",
    thumbnail: "/gallery/legla/t_0017.jpg",
  },
  {
    original: "/gallery/legla/p_0018.jpg",
    thumbnail: "/gallery/legla/t_0018.jpg",
  },
  {
    original: "/gallery/legla/p_0019.jpg",
    thumbnail: "/gallery/legla/t_0019.jpg",
  },
  {
    original: "/gallery/legla/p_0020.jpg",
    thumbnail: "/gallery/legla/t_0020.jpg",
  },
  {
    original: "/gallery/legla/p_0021.jpg",
    thumbnail: "/gallery/legla/t_0021.jpg",
  },
  {
    original: "/gallery/legla/p_0022.jpg",
    thumbnail: "/gallery/legla/t_0022.jpg",
  },
  {
    original: "/gallery/legla/p_0023.jpg",
    thumbnail: "/gallery/legla/t_0023.jpg",
  },
  {
    original: "/gallery/legla/p_0024.jpg",
    thumbnail: "/gallery/legla/t_0024.jpg",
  },
  {
    original: "/gallery/legla/p_0025.jpg",
    thumbnail: "/gallery/legla/t_0025.jpg",
  },
  {
    original: "/gallery/legla/p_0026.jpg",
    thumbnail: "/gallery/legla/t_0026.jpg",
  },
  {
    original: "/gallery/legla/p_0027.jpg",
    thumbnail: "/gallery/legla/t_0027.jpg",
  },
  {
    original: "/gallery/legla/p_0028.jpg",
    thumbnail: "/gallery/legla/t_0028.jpg",
  },
  {
    original: "/gallery/legla/p_0029.jpg",
    thumbnail: "/gallery/legla/t_0029.jpg",
  },
  {
    original: "/gallery/legla/p_0030.jpg",
    thumbnail: "/gallery/legla/t_0031.jpg",
  },
  {
    original: "/gallery/legla/p_0032.jpg",
    thumbnail: "/gallery/legla/t_0032.jpg",
  },
  {
    original: "/gallery/legla/p_0033.jpg",
    thumbnail: "/gallery/legla/t_0033.jpg",
  },
  {
    original: "/gallery/legla/p_0034.jpg",
    thumbnail: "/gallery/legla/t_0034.jpg",
  },
  {
    original: "/gallery/legla/p_0035.jpg",
    thumbnail: "/gallery/legla/t_0035.jpg",
  },
  {
    original: "/gallery/legla/p_0036.jpg",
    thumbnail: "/gallery/legla/t_0036.jpg",
  },
];

const sekcii = [
  {
    original: "/gallery/sekcii/p_0001.jpg",
    thumbnail: "/gallery/sekcii/t_0001.jpg",
  },
  {
    original: "/gallery/sekcii/p_0002.jpg",
    thumbnail: "/gallery/sekcii/t_0002.jpg",
  },
  {
    original: "/gallery/sekcii/p_0003.jpg",
    thumbnail: "/gallery/sekcii/t_0003.jpg",
  },
  {
    original: "/gallery/sekcii/p_0004.jpg",
    thumbnail: "/gallery/sekcii/t_0004.jpg",
  },
  {
    original: "/gallery/sekcii/p_0005.jpg",
    thumbnail: "/gallery/sekcii/t_0005.jpg",
  },
  {
    original: "/gallery/sekcii/p_0006.jpg",
    thumbnail: "/gallery/sekcii/t_0006.jpg",
  },
  {
    original: "/gallery/sekcii/p_0007.jpg",
    thumbnail: "/gallery/sekcii/t_0007.jpg",
  },
  {
    original: "/gallery/sekcii/p_0008.jpg",
    thumbnail: "/gallery/sekcii/t_0008.jpg",
  },
  {
    original: "/gallery/sekcii/p_0009.jpg",
    thumbnail: "/gallery/sekcii/t_0009.jpg",
  },
  {
    original: "/gallery/sekcii/p_0010.jpg",
    thumbnail: "/gallery/sekcii/t_0010.jpg",
  },
  {
    original: "/gallery/sekcii/p_0011.jpg",
    thumbnail: "/gallery/sekcii/t_0011.jpg",
  },
  {
    original: "/gallery/sekcii/p_0012.jpg",
    thumbnail: "/gallery/sekcii/t_0012.jpg",
  },
  {
    original: "/gallery/sekcii/p_0013.jpg",
    thumbnail: "/gallery/sekcii/t_0013.jpg",
  },
  {
    original: "/gallery/sekcii/p_0014.jpg",
    thumbnail: "/gallery/sekcii/t_0014.jpg",
  },
  {
    original: "/gallery/sekcii/p_0015.jpg",
    thumbnail: "/gallery/sekcii/t_0015.jpg",
  },
  {
    original: "/gallery/sekcii/p_0016.jpg",
    thumbnail: "/gallery/sekcii/t_0016.jpg",
  },
  {
    original: "/gallery/sekcii/p_0017.jpg",
    thumbnail: "/gallery/sekcii/t_0017.jpg",
  },
  {
    original: "/gallery/sekcii/p_0018.jpg",
    thumbnail: "/gallery/sekcii/t_0018.jpg",
  },
  {
    original: "/gallery/sekcii/p_0019.jpg",
    thumbnail: "/gallery/sekcii/t_0019.jpg",
  },
  {
    original: "/gallery/sekcii/p_0020.jpg",
    thumbnail: "/gallery/sekcii/t_0020.jpg",
  },
  {
    original: "/gallery/sekcii/p_0021.jpg",
    thumbnail: "/gallery/sekcii/t_0021.jpg",
  },
  {
    original: "/gallery/sekcii/p_0022.jpg",
    thumbnail: "/gallery/sekcii/t_0022.jpg",
  },
  {
    original: "/gallery/sekcii/p_0023.jpg",
    thumbnail: "/gallery/sekcii/t_0023.jpg",
  },
  {
    original: "/gallery/sekcii/p_0024.jpg",
    thumbnail: "/gallery/sekcii/t_0024.jpg",
  },
  {
    original: "/gallery/sekcii/p_0025.jpg",
    thumbnail: "/gallery/sekcii/t_0025.jpg",
  },
  {
    original: "/gallery/sekcii/p_0026.jpg",
    thumbnail: "/gallery/sekcii/t_0026.jpg",
  },
  {
    original: "/gallery/sekcii/p_0027.jpg",
    thumbnail: "/gallery/sekcii/t_0027.jpg",
  },
  {
    original: "/gallery/sekcii/p_0028.jpg",
    thumbnail: "/gallery/sekcii/t_0028.jpg",
  },
  {
    original: "/gallery/sekcii/p_0029.jpg",
    thumbnail: "/gallery/sekcii/t_0029.jpg",
  },
  {
    original: "/gallery/sekcii/p_0030.jpg",
    thumbnail: "/gallery/sekcii/t_0030.jpg",
  },
  {
    original: "/gallery/sekcii/p_0031.jpg",
    thumbnail: "/gallery/sekcii/t_0031.jpg",
  },
  {
    original: "/gallery/sekcii/p_0032.jpg",
    thumbnail: "/gallery/sekcii/t_0032.jpg",
  },
  {
    original: "/gallery/sekcii/p_0033.jpg",
    thumbnail: "/gallery/sekcii/t_0033.jpg",
  },
  {
    original: "/gallery/sekcii/p_0034.jpg",
    thumbnail: "/gallery/sekcii/t_0034.jpg",
  },
  {
    original: "/gallery/sekcii/p_0035.jpg",
    thumbnail: "/gallery/sekcii/t_0035.jpg",
  },
  {
    original: "/gallery/sekcii/p_0036.jpg",
    thumbnail: "/gallery/sekcii/t_0036.jpg",
  },
  {
    original: "/gallery/sekcii/p_0037.jpg",
    thumbnail: "/gallery/sekcii/t_0037.jpg",
  },
  {
    original: "/gallery/sekcii/p_0038.jpg",
    thumbnail: "/gallery/sekcii/t_0038.jpg",
  },
  {
    original: "/gallery/sekcii/p_0039.jpg",
    thumbnail: "/gallery/sekcii/t_0039.jpg",
  },
  {
    original: "/gallery/sekcii/p_0040.jpg",
    thumbnail: "/gallery/sekcii/t_0040.jpg",
  },
  {
    original: "/gallery/sekcii/p_0041.jpg",
    thumbnail: "/gallery/sekcii/t_0041.jpg",
  },
  {
    original: "/gallery/sekcii/p_0042.jpg",
    thumbnail: "/gallery/sekcii/t_0042.jpg",
  },
  {
    original: "/gallery/sekcii/p_0043.jpg",
    thumbnail: "/gallery/sekcii/t_0043.jpg",
  },
  {
    original: "/gallery/sekcii/p_0044.jpg",
    thumbnail: "/gallery/sekcii/t_0044.jpg",
  },
  {
    original: "/gallery/sekcii/p_0045.jpg",
    thumbnail: "/gallery/sekcii/t_0045.jpg",
  },
  {
    original: "/gallery/sekcii/p_0046.jpg",
    thumbnail: "/gallery/sekcii/t_0046.jpg",
  },
  {
    original: "/gallery/sekcii/p_0047.jpg",
    thumbnail: "/gallery/sekcii/t_0047.jpg",
  },
  {
    original: "/gallery/sekcii/p_0048.jpg",
    thumbnail: "/gallery/sekcii/t_0048.jpg",
  },
  {
    original: "/gallery/sekcii/p_0049.jpg",
    thumbnail: "/gallery/sekcii/t_0049.jpg",
  },
  {
    original: "/gallery/sekcii/p_0050.jpg",
    thumbnail: "/gallery/sekcii/t_0050.jpg",
  },
  {
    original: "/gallery/sekcii/p_0051.jpg",
    thumbnail: "/gallery/sekcii/t_0051.jpg",
  },
  {
    original: "/gallery/sekcii/p_0052.jpg",
    thumbnail: "/gallery/sekcii/t_0052.jpg",
  },
];

const stulbishta = [
  {
    original: "/gallery/stulbishta/p_0001.jpg",
    thumbnail: "/gallery/stulbishta/t_0001.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0002.jpg",
    thumbnail: "/gallery/stulbishta/t_0002.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0003.jpg",
    thumbnail: "/gallery/stulbishta/t_0003.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0004.jpg",
    thumbnail: "/gallery/stulbishta/t_0004.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0005.jpg",
    thumbnail: "/gallery/stulbishta/t_0005.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0006.jpg",
    thumbnail: "/gallery/stulbishta/t_0006.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0007.jpg",
    thumbnail: "/gallery/stulbishta/t_0007.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0008.jpg",
    thumbnail: "/gallery/stulbishta/t_0008.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0009.jpg",
    thumbnail: "/gallery/stulbishta/t_0009.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0010.jpg",
    thumbnail: "/gallery/stulbishta/t_0010.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0011.jpg",
    thumbnail: "/gallery/stulbishta/t_0011.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0012.jpg",
    thumbnail: "/gallery/stulbishta/t_0012.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0013.jpg",
    thumbnail: "/gallery/stulbishta/t_0013.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0014.jpg",
    thumbnail: "/gallery/stulbishta/t_0014.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0015.jpg",
    thumbnail: "/gallery/stulbishta/t_0015.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0016.jpg",
    thumbnail: "/gallery/stulbishta/t_0016.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0017.jpg",
    thumbnail: "/gallery/stulbishta/t_0017.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0018.jpg",
    thumbnail: "/gallery/stulbishta/t_0018.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0019.jpg",
    thumbnail: "/gallery/stulbishta/t_0019.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0020.jpg",
    thumbnail: "/gallery/stulbishta/t_0020.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0021.jpg",
    thumbnail: "/gallery/stulbishta/t_0021.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0022.jpg",
    thumbnail: "/gallery/stulbishta/t_0022.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0023.jpg",
    thumbnail: "/gallery/stulbishta/t_0023.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0024.jpg",
    thumbnail: "/gallery/stulbishta/t_0024.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0025.jpg",
    thumbnail: "/gallery/stulbishta/t_0025.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0026.jpg",
    thumbnail: "/gallery/stulbishta/t_0026.jpg",
  },
  {
    original: "/gallery/stulbishta/p_0027.jpg",
    thumbnail: "/gallery/stulbishta/t_0027.jpg",
  },
];

const vrati = [
  {
    original: "/gallery/vrati/p_0001.jpg",
    thumbnail: "/gallery/vrati/t_0001.jpg",
  },
  {
    original: "/gallery/vrati/p_0002.jpg",
    thumbnail: "/gallery/vrati/t_0002.jpg",
  },
  {
    original: "/gallery/vrati/p_0003.jpg",
    thumbnail: "/gallery/vrati/t_0003.jpg",
  },
  {
    original: "/gallery/vrati/p_0004.jpg",
    thumbnail: "/gallery/vrati/t_0004.jpg",
  },
  {
    original: "/gallery/vrati/p_0005.jpg",
    thumbnail: "/gallery/vrati/t_0005.jpg",
  },
  {
    original: "/gallery/vrati/p_0006.jpg",
    thumbnail: "/gallery/vrati/t_0006.jpg",
  },
  {
    original: "/gallery/vrati/p_0007.jpg",
    thumbnail: "/gallery/vrati/t_0007.jpg",
  },
  {
    original: "/gallery/vrati/p_0008.jpg",
    thumbnail: "/gallery/vrati/t_0008.jpg",
  },
  {
    original: "/gallery/vrati/p_0009.jpg",
    thumbnail: "/gallery/vrati/t_0009.jpg",
  },
  {
    original: "/gallery/vrati/p_0010.jpg",
    thumbnail: "/gallery/vrati/t_0010.jpg",
  },
  {
    original: "/gallery/vrati/p_0011.jpg",
    thumbnail: "/gallery/vrati/t_0011.jpg",
  },
  {
    original: "/gallery/vrati/p_0012.jpg",
    thumbnail: "/gallery/vrati/t_0012.jpg",
  },
  {
    original: "/gallery/vrati/p_0013.jpg",
    thumbnail: "/gallery/vrati/t_0013.jpg",
  },
  {
    original: "/gallery/vrati/p_0014.jpg",
    thumbnail: "/gallery/vrati/t_0014.jpg",
  },
  {
    original: "/gallery/vrati/p_0015.jpg",
    thumbnail: "/gallery/vrati/t_0015.jpg",
  },
  {
    original: "/gallery/vrati/p_0016.jpg",
    thumbnail: "/gallery/vrati/t_0016.jpg",
  },
  {
    original: "/gallery/vrati/p_0017.jpg",
    thumbnail: "/gallery/vrati/t_0017.jpg",
  },
  {
    original: "/gallery/vrati/p_0018.jpg",
    thumbnail: "/gallery/vrati/t_0018.jpg",
  },
  {
    original: "/gallery/vrati/p_0019.jpg",
    thumbnail: "/gallery/vrati/t_0019.jpg",
  },
  {
    original: "/gallery/vrati/p_0020.jpg",
    thumbnail: "/gallery/vrati/t_0020.jpg",
  },
]

export { kuhni, legla, sekcii, stulbishta, vrati };