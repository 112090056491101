import React from "react"
import Layout from "../../components/layout"
import ImageGallery from "react-image-gallery"
import Metadata from "../../components/metadata"
import styled from 'styled-components';
import { useIntl, Link } from "gatsby-plugin-intl"
import { kuhni, legla, stulbishta, sekcii, vrati } from "../../data/gallery"

const Container = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;

  [aria-current="page"] {
    font-size: 14px;
    font-weight: bold;
  }
`;

const Gallery = ({ location }) => {
  const intl = useIntl()
  let categoryToShow = kuhni;
  const uriCategory = location.pathname.match(/\/gallery\/([a-z]+)\/?/);

  if (uriCategory !== null) {
    switch (uriCategory[1]) {
      case 'legla':
        categoryToShow = legla;
        break;
      case 'vrati':
        categoryToShow = vrati;
        break;
      case 'stulbishta':
        categoryToShow = stulbishta;
        break;
      case 'sekcii':
        categoryToShow = sekcii;
        break;
      default: 
        categoryToShow = kuhni;
    }
  }

  return (
    <Layout>
      <Metadata title={intl.formatMessage({ id: "gallery.title" })} />
      <Container className="container ">
        <div className="d-flex flex-row justify-content-center">
          <Link to="/gallery/kuhni/" className="d-block px-1 px-md-3 py-2 pb-4">{intl.formatMessage({ id: "gallery.kuhni" })}</Link>
          <Link to="/gallery/sekcii/" className="d-block px-1 px-md-3 py-2 pb-4">{intl.formatMessage({ id: "gallery.sekcii" })}</Link>
          <Link to="/gallery/legla/" className="d-block px-1 px-md-3 py-2 pb-4">{intl.formatMessage({ id: "gallery.legla" })}</Link>
          <Link to="/gallery/vrati/" className="d-block px-1 px-md-3 py-2 pb-4">{intl.formatMessage({ id: "gallery.vrati" })}</Link>
          <Link to="/gallery/stulbishta/" className="d-block px-1 px-md-3 py-2 pb-4">{intl.formatMessage({ id: "gallery.stulbishta" })}</Link>
        </div>
      </Container>
      <ImageGallery
        items={categoryToShow}
        showBullets={false}
        showIndex={false}
        showThumbnails={true}
        showNav={true}
        lazyLoad={true}
        showPlayButton={false}
        showFullscreenButton={false}
      />
    </Layout>
  )
}


export default Gallery
